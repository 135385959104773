// Libs
import React from "react";

// import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { formatPrice } from "../../containers/prices";

// CSS
import "./price-history.scss";

const HistoricPricesUnavailable = () => (
    <div className="c-price-history u-padding-bottom--2"><span>We currently do not have sufficient historical price data for this product.</span></div>
);

const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };

    return new Date(dateString).toLocaleDateString(undefined, options);
};

const priceChangeTable = (priceChanges, region, variantMetadata) => {
    if (priceChanges && priceChanges.length > 0) {
        return (
            <table className="c-price-history__table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Price Change</th>
                        <th>New Price</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        priceChanges.map((priceChangesOnDay, index, array) => {

                            const priceChangeCurrentDay = priceChangesOnDay[0];
                            const priceChangePreviousDay = index !== array.length ? array && array[index + 1] && array[index + 1][0] : null;

                            if (!priceChangePreviousDay || !priceChangePreviousDay.value || priceChangeCurrentDay.value !== priceChangePreviousDay.value) {
                                let valueChange = 0;
                                if (priceChangePreviousDay) {
                                    valueChange = priceChangeCurrentDay.value - priceChangePreviousDay.value;
                                }

                                const priceChangeClass = valueChange > 0 ? "arrow-up g-flex__item" : "arrow-down g-flex__item";
                                const highRowClass = priceChangeCurrentDay.value === variantMetadata.highestHistoricPrice ? "c-price-history__table-row--high" : "";
                                const lowRowClass = priceChangeCurrentDay.value === variantMetadata.lowestHistoricPrice ? "c-price-history__table-row--low" : "";

                                return (
                                    <tr key={`price-change-${index}`} className={`${highRowClass} ${lowRowClass}`}>
                                        <td>{formatDate(priceChangeCurrentDay.createdAt)}</td>
                                        <td className="g-flex__container g-flex__container--align-i-center">
                                            {valueChange !== 0 &&
                                                <>
                                                    <div className={priceChangeClass}></div>
                                                    <span className="g-flex__item">{formatPrice(valueChange, region)}</span>
                                                </>
                                            }
                                        </td>
                                        <td>{formatPrice((priceChangeCurrentDay.value), region)}</td>
                                    </tr>
                                );
                            }
                        })
                    }
                </tbody>
            </table>
        );
    }
};


export const PriceHistory = (props) => {

    if (!props.priceChanges || !props.region || props.priceChanges.length < 1 || !props.priceMetadata) {
        return <HistoricPricesUnavailable />;
    }

    const variantMetadata = props.subVariantName === "all" ? props.priceMetadata.filter(item => item.subVariantName === null) : props.priceMetadata.filter(item => item.subVariantName === props.subVariantName);
    const variantPriceChange = props.subVariantName === "all" ? props.priceChanges.map(priceChangesOnDay => priceChangesOnDay.sort((a, b) => b.value - a.value)) : props.priceChanges.map(priceChangesOnDay => priceChangesOnDay.filter(item => item.subVariantName === props.subVariantName).sort((a, b) => b.value - a.value));

    if (variantPriceChange.length >= 1 && variantMetadata) {
        return (
            <div className="c-price-history u-margin-bottom--1">
                {priceChangeTable(props.priceChanges, props.region, variantMetadata)}
            </div>
        );
    }
};


// const getGraphData = (productVariants) => {
//     const series = productVariants.map((variant) => {
//         const name = `${variant.supplierUrl} size ${variant.size}`;

//         const data = variant.historicPrices.map((price) => {
//             return {
//                 fullDate: price.createdAt,
//                 value: parseFloat(price.value / 100).toFixed(2),
//                 date: new Date(price.createdAt).getTime()
//             }
//         });

//         const sortedDataByDate = data.sort((a, b) => {
//             return (a.date < b.date) ? -1 : ((a.date > b.date) ? 1 : 0);
//         });

//         return {
//             name,
//             data: sortedDataByDate
//         }

//     })

//     return series;
// }

// export const PriceHistoryGraph = ({ productVariants }) => {
//     if (productVariants && productVariants.length > 0) {
//         const cleanProductVariants = productVariants.filter((variant) => {
//             if (!variant.historicPrices || variant.historicPrices.length <= 1) {
//                 return false;
//             }
//             return true;
//         })

//         if (!cleanProductVariants || cleanProductVariants.length < 1) {
//             return <p>We currently do not have sufficient historical price data for this product.</p>;
//         }

//         if (cleanProductVariants && cleanProductVariants.length >= 1) {
//             const data = getGraphData(cleanProductVariants);
//             const COLORS = [

//                 '#00205b',
//                 '#009f4d',
//                 '#84bd00',
//                 '#fe5000',
//                 '#e4002b',
//                 '#da1884',
//                 '#a51890',
//                 '#0077c8',
//                 '#74d2e7',
//                 '#48a9c5',
//                 '#0085ad',
//                 '#005670',
//             ];

//             const CustomizedDot = (props) => {
//                 const {
//                     cx, cy, stroke, payload, value,
//                 } = props;

//                 if (value < 14) {
//                     return (
//                         <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
//                             <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z" />
//                         </svg>
//                     );
//                 }

//                 return (
//                     <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
//                         <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z" />
//                     </svg>
//                 );
//             };

//             return (
//                 <ResponsiveContainer minHeight={250} height="80%">
//                     <LineChart data={data} margin={{
//                         top: 20, right: 20, left: 0, bottom: 20,
//                     }}>
//                         <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
//                         <XAxis
//                             dataKey="date"
//                             //tickFormatter={timeStr => moment(timeStr).local().format('MMM Do')}
//                             tickFormatter={timeStr => timeStr.getDate()}
//                             type='number'
//                             scale='time'
//                             domain={['auto', 'auto']}
//                         //allowDuplicatedCategory={true}
//                         />
//                         <YAxis
//                             dataKey={(v) => parseFloat(v.value).toFixed(2)}
//                             type='number'
//                             domain={[
//                                 dataMin => (Math.ceil(Math.abs(dataMin) / 5) * 5 - 10),
//                                 dataMax => (Math.ceil(Math.abs(dataMax) / 5) * 5 + 10)
//                             ]} />
//                         <Legend />
//                         {data.map((series, index) => {
//                             const colorIndex = series.name.includes('amazon') ? '#ff9900' : COLORS[index];
//                             return <Line
//                                 type="monotone"
//                                 stroke={colorIndex}
//                                 fill={colorIndex}
//                                 // dot={<CustomizedDot />}
//                                 dataKey={(v) => parseFloat(v.value).toFixed(2)}
//                                 data={series.data}
//                                 name={series.name}
//                                 key={series.name}
//                             />
//                         })}

//                         <Tooltip
//                         //labelFormatter={timeStr => moment(timeStr).local().format('MMM Do YY')}
//                         labelFormatter={timeStr => timeStr.getDate()}
//                         />
//                     </LineChart>
//                 </ResponsiveContainer>
//             )
//         }
//     }

//     return null;
// };