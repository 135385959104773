import React from "react";

import { Link } from "gatsby";

import { Prices } from "../../containers/prices";
import { getPath } from "../../helpers/get-path";
import { ShopNow } from "../buttons/shop-now";
import { Image } from "../image";

// CSS
import "./all-variants.scss";

export const AllVariants = (props) => {
    if (props.productVariants && props.productVariants.length > 1) {

        return (
            <div className="c-all-variants u-margin-top--2">
                <h3 className="c-all-variants__title u-margin-top--2 u-margin-bottom--2">Available variants</h3>
                <div className="g-flex__container">
                    {props.productVariants && props.productVariants.map((product) => {
                        const categorySlug = product.categorys && product.categorys[0] && product.categorys[0].slug;
                        const subCategorySlug = product.subCategorys && product.subCategorys[0] && product.subCategorys[0].slug;

                        const productPath = getPath(props.languageRegion, product.slug, categorySlug, subCategorySlug);

                        return (
                            <div key={`variant-${product.variantName}`} className="g-flex__item g-flex__container g-flex__container--column-always g-flex__container--align-i-center c-all-variants__thumbnail u-padding-bottom--2">
                                <Image
                                    imgClassName="c-all-variants__thumbnail-image"
                                    image={product.images && product.images[0]}
                                    alt=""
                                    thumbnail={true}
                                />
                                <Prices variantPrices={product.variantPrices} region={props.languageRegion.slice(-2)} subVariantName={props.subVariantName} productPath={productPath} showDealButton={false} bestPriceOnly={true} />
                                <Link className="c-all-variants__link" to={productPath}>
                                    <ShopNow componentClass="g-flex__item u-margin-top--05" buttonClass="c-shop-now-button__link--primary-color u-width-100">{product.variantName}</ShopNow>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};
