import React, { useState } from "react";

import "./information-note.scss";



export const InformationNote = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    return (
        <button className="g-flex__container g-flex__container--column-always c-information-note" onClick={handleClick}>
            <div className="g-flex__item g-flex__item--full-width c-information-note__container">
                <div className={`g-flex__item g-flex__item--full-width g-flex__container g-flex__container--align-i-center c-information-note__arrow-area ${isOpen ? "c-information-note__arrow-area--open" : "c-information-note__arrow-area--closed"}`}>
                    <div className="g-flex__item">{props.children[0]}</div>
                    <span className="g-flex__item">{isOpen ? "Close" : "Read More"}</span>
                </div>
                <div className={isOpen ? "c-information-note__content c-information-note__content--open" : "c-information-note__content c-information-note__content--closed"}>
                    {props.children.map((element, index) => {
                        return index !== 0 && element;
                    })}
                </div>
            </div>
            <div className={`g-flex__item g-flex__item--full-width g-flex__container g-flex__container--justify-center g-flex__container--align-center c-information-note__arrow-area-bottom ${isOpen ? "c-information-note__arrow-area-bottom--open" : "c-information-note__arrow-area-bottom--closed"}`}>
                <span className="g-flex__item">{isOpen ? "Close" : "Read More"}</span>
            </div>
        </button>
    );
};
