import React, { useState, useContext } from "react";

import { graphql } from "gatsby";

// import { CustomerReviewRating } from '../../components/customer-review-rating';
// import { VerdictProduct } from '../../components/verdict-product';
//import { ProductsStacked } from '../../containers/products-stacked';
import { AllVariants } from "../../components/all-variants";
import { Image } from "../../components/image";
import { InformationNote } from "../../components/information-note";
import { PriceHistory } from "../../components/price-history";
import { RatingBar } from "../../components/rating-bar";
import { SubVariantFilter } from "../../components/sub-variant-filter";
import { VerdictListing } from "../../components/verdict-listing";
import { Prices } from "../../containers/prices";
import { LanguageRegionContext } from "../../context-providers/language-region-context";
import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";
import ErrorTemplate from "../error";


import "./product.scss";

const groupArrayByYearMonthDay = (arr) => {
    const arrayByYearMonthDay = [...arr.reduce((acc, o) =>
        acc.set(o["createdAt"].split("T")[0], (acc.get(o["createdAt"].split("T")[0]) || []).concat(o))
    , new Map()).values()];

    const ordered = arrayByYearMonthDay.sort((a, b) => {
        return new Date(b[0].createdAt) - new Date(a[0].createdAt);
    });

    return ordered;
};

const Product = (props) => {
    const [currentSubVariant, setCurrentSubVariant] = useState("all");

    const handleSubVariantClick = (newSubVariant) => {
        console.log("newSubVariant", newSubVariant);

        setCurrentSubVariant(newSubVariant);
    };

    const title = `${props.product.brand.formattedName} ${props.product.formattedName}`;

    let priceChangesByDay = [];
    if (props.product.variantPriceChanges[props.languageRegion.slice(-2)]) {
        priceChangesByDay = groupArrayByYearMonthDay(props.product.variantPriceChanges[props.languageRegion.slice(-2)]);
    }

    return (
        <>
            <article className="c-product">
                <div className="c-product__container u-padding-top--2">
                    <div className="g-grid__container--product-item">
                        <div className="g-grid__item--product-item-2">
                            <div className="g-grid__container--stack">
                                <div className="g-grid__item--stack-1">
                                    <h2 className="u-visually-hidden">Product details</h2>
                                </div>
                                <div className="g-grid__item--stack-2">
                                    {/* <CustomerReviewRating
                                      customerReviewRating={props.product.brand.customerReviewRating}
                                      customerReviewRatingLength={props.product.brand.customerReviewRatingLength}
                                      customerReviewRatingUrl={props.product.brand.customerReviewRatingUrl}
                                    /> */}
                                </div>
                                <div className="g-grid__item--stack-3">
                                    <RatingBar headingLevel={3} heading={props.product.rating1Heading} rating={props.product.rating1 || 0} />
                                    <RatingBar headingLevel={3} heading={props.product.rating2Heading} rating={props.product.rating2 || 0} />
                                    <RatingBar headingLevel={3} heading={props.product.rating3Heading} rating={props.product.rating3 || 0} />

                                </div>
                            </div>
                        </div>
                        <div className="g-grid__item--product-item-1">
                            <Image
                                imgClassName="c-product__image"
                                image={props.product.images && props.product.images[0]}
                                alt=""
                            />
                        </div>
                        <div className="g-grid__item--product-item-3">
                            <SubVariantFilter componentId="prices" variantPrices={props.product.variantPrices} region={props.languageRegion.slice(-2)} currentSubVariant={currentSubVariant} handleSubVariantClick={handleSubVariantClick} />
                            <Prices variantPrices={props.product.variantPrices} priceMetadata={props.product.variantPriceMetadata[props.languageRegion.slice(-2)]} region={props.languageRegion.slice(-2)} subVariantName={currentSubVariant} internalLink={false} showDealButton={true} priceGuide={true} />
                        </div>
                        <div className="g-grid__item--product-item-4">
                            {
                                props.productVariants && props.productVariants.length > 0 &&
                                <AllVariants
                                    productVariants={props.productVariants}
                                    subVariantName={currentSubVariant}
                                    languageRegion={props.languageRegion}
                                    category={props.category}
                                    subCategory={props.subCategory}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="c-product-spacer"></div>
                <div className="g-grid__container g-grid__container--product-content">
                    <div className="g-grid__item g-grid__item--features">
                        <div>
                            <div className="g-flex__container g-flex__container--column-always u-width-100">
                                <h2 className="g-flex__item c-product__title"><span>Review of {title}</span></h2>

                                <VerdictListing
                                    ratingBarTitle="Our Verdict"
                                    rating={props.product.ratingsTotal || 0}
                                />
                                {props.product.featureBulletPoints && props.product.featureBulletPoints.length > 0 &&
                                    <ul className="c-product__feature-list u-padding-top--1 u-padding-bottom--1">
                                        {
                                            props.product.featureBulletPoints.map((feature, index) => {
                                                return <li key={`product-feature-bullet-point-${index}`} className="g-flex__item c-product__feature-list-item">{feature}</li>;
                                            })
                                        }
                                    </ul>
                                }
                                {/* {review &&
                  <div className="g-flex__container g-flex__container--column-always u-width-100 u-padding-top--1 u-padding-bottom--2">
                    <p className="g-flex__item c-product__paragraph">{review}</p>
                  </div>
                } */}
                                {props.product.rating1Text &&
                                    <div className="g-flex__container g-flex__container--column-always u-width-100 u-padding-top--1 u-padding-bottom--2">
                                        {props.product.rating1Heading && props.product.rating1Text && <h3 className="g-flex__item c-product__title"><span>{props.product.rating1Heading}</span></h3>}
                                        {props.product.rating1Heading && props.product.rating1Text && props.product.rating1Text && props.product.rating1TextExplainer &&
                                            <InformationNote>
                                                <h4>What is our {props.product.rating1Heading}?</h4>
                                                {props.product.rating1TextExplainer.map((text, index) => <p key={`product-rating1-explainer-${index}`}>{text}</p>)}
                                            </InformationNote>
                                        }
                                        {props.product.rating1Heading && props.product.rating1Text &&
                                            <h4 className="u-visually-hidden">{props.product.rating1Heading} Review</h4>
                                        }
                                        {
                                            props.product.rating1Features && props.product.rating1Features.length > 0 &&
                                            <ul className="c-product__feature-list c-product__feature-list--plus u-padding-top--1">
                                                {
                                                    props.product.rating1Features.map((feature, index) => {
                                                        return <li key={`product-rating1-feature-${index}`} className="g-flex__item c-product__feature-list-item">{feature}</li>;
                                                    })
                                                }
                                            </ul>
                                        }
                                        {
                                            props.product.rating1Text &&
                                            props.product.rating1Text.map((text, index) => {
                                                return <p key={`product-rating1-text-${index}`} className="g-flex__item c-product__paragraph u-margin-top--1">{text}</p>;
                                            })
                                        }
                                    </div>
                                }
                                {props.product.rating2Text &&
                                    <div className="g-flex__container g-flex__container--column-always u-width-100 u-padding-top--1 u-padding-bottom--2">
                                        {props.product.rating2Heading && props.product.rating2Text && <h3 className="g-flex__item c-product__title"><span>{props.product.rating2Heading}</span></h3>}
                                        {props.product.rating2Heading && props.product.rating2Text && props.product.rating2Text && props.product.rating2TextExplainer &&
                                            <InformationNote>
                                                <h4>What is our {props.product.rating2Heading}?</h4>
                                                {props.product.rating2TextExplainer.map((text, index) => <p key={`product-rating2-explainer-${index}`}>{text}</p>)}
                                            </InformationNote>
                                        }
                                        {props.product.rating2Heading && props.product.rating2Text &&
                                            <h4 className="u-visually-hidden">{props.product.rating2Heading} Review</h4>
                                        }
                                        {
                                            props.product.rating2Features && props.product.rating2Features.length > 0 &&
                                            <ul className="c-product__feature-list c-product__feature-list--plus u-padding-top--1">
                                                {
                                                    props.product.rating2Features.map((feature, index) => {
                                                        return <li key={`product-rating2-feature-${index}`} className="g-flex__item c-product__feature-list-item">{feature}</li>;
                                                    })
                                                }
                                            </ul>
                                        }
                                        {props.product.rating2Text && props.product.rating2Text.map((text, index) => {
                                            return <p key={`product-rating2-text-${index}`} className="g-flex__item c-product__paragraph u-margin-top--1">{text}</p>;
                                        })}
                                    </div>
                                }
                                {props.product.rating3Text &&
                                    <div className="g-flex__container g-flex__container--column-always u-width-100 u-padding-top--1 u-padding-bottom--2">
                                        {props.product.rating3Heading && props.product.rating3Text && <h3 className="g-flex__item c-product__title"><span>{props.product.rating3Heading}</span></h3>}
                                        {props.product.rating3Heading && props.product.rating3Text && props.product.rating3Text && props.product.rating3TextExplainer &&
                                            <InformationNote>
                                                <h4>What is our {props.product.rating3Heading}?</h4>
                                                {props.product.rating3TextExplainer.map((text, index) => <p key={`product-rating3-explainer-${index}`}>{text}</p>)}
                                            </InformationNote>
                                        }
                                        {props.product.rating3Heading && props.product.rating3Text &&
                                            <h4 className="u-visually-hidden">{props.product.rating3Heading} Review</h4>
                                        }
                                        {
                                            props.product.rating3Features && props.product.rating3Features.length > 0 &&
                                            <ul className="c-product__feature-list c-product__feature-list--plus u-padding-top--1">
                                                {
                                                    props.product.rating3Features.map((feature, index) => {
                                                        return <li key={`product-rating3-feature-${index}`} className="g-flex__item c-product__feature-list-item">{feature}</li>;
                                                    })
                                                }
                                            </ul>
                                        }
                                        {props.product.rating3Text && props.product.rating3Text.map((text, index) => {
                                            return <p key={`product-rating2-text-${index}`} className="g-flex__item c-product__paragraph u-margin-top--1">{text}</p>;
                                        })}
                                    </div>
                                }
                                <div className="g-flex__container g-flex__container--column-always u-width-100 u-padding-top--1 u-padding-bottom--2">
                                    {props.productPrices && props.productPrices[0] && props.productPrices[0].historicPrices && props.productPrices[0].historicPrices.length >= 1 &&
                                        <>
                                            <h3 className="g-flex__item c-product__title"><span>Price History Graph</span></h3>
                                            <div style={{ position: "relative", width: "100%", paddingBottom: "250px" }}>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        top: 0,
                                                    }}
                                                >
                                                    {/* <PriceHistoryGraph productVariants={props.productVariants} /> */}
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="g-grid__item g-grid__item--price-history">
                        <h2 className="c-product__title"><span>Price History</span></h2>
                        <div>
                            <SubVariantFilter componentId="price-history" variantPrices={props.product.variantPrices} region={props.languageRegion.slice(-2)} currentSubVariant={currentSubVariant} handleSubVariantClick={handleSubVariantClick} />
                        </div>
                        <PriceHistory priceChanges={priceChangesByDay} priceMetadata={props.product.variantPriceMetadata[props.languageRegion.slice(-2)]} region={props.languageRegion.slice(-2)} subVariantName={currentSubVariant} />
                    </div>
                </div>
            </article >
        </>
    );
};



const ProductTemplate = ({ pageContext, data }) => {
    const { languageRegion } = useContext(LanguageRegionContext);


    const title = `${data.category.formattedName} ${data.product.brand.formattedName} ${data.product.formattedName}`;

    const pageDetails = {
        formattedName: data.product && data.product.formattedName,
        color: data.product && data.product.color,
        category: data.category,
        subCategory: data.subCategory,
        brand: data.product.brand,
        currentLanguageRegion: pageContext.currentLanguageRegion,
        title: pageContext.title,
        meta: pageContext.meta,
        script: pageContext.script,
        urlPath: pageContext.urlPath
    };

    const productVariants = [data.productVariant1, data.productVariant2, data.productVariant3, data.productVariant4, data.productVariant5, data.productVariant6, data.productVariant7, data.productVariant8, data.productVariant9, data.productVariant10, data.productVariant11, data.productVariant12].filter(e => e);

    if (data.product) {
        return (
            <DefaultLayout pageDetails={pageDetails}>
                <ContentLayout affiliateDisclosure={true} title={title}>
                    <Product
                        product={data.product}
                        category={data.category}
                        subCategory={data.subCategory}
                        productVariants={productVariants}
                        languageRegion={languageRegion}
                        title={pageContext.title}
                    // alternativeProductVariants
                    />
                </ContentLayout>
            </DefaultLayout>
        );
    }

    return (
        <ErrorTemplate pageDetails={pageDetails} />
    );
};


export const ProductQueryFragment = graphql`
    fragment ProductFields on Product {
        PK
        SK
        givenName
        formattedName
        variantName

        slug
        noVariantSlug
        noBrandSlug
        variantSlug

        categoryIds
        subCategoryIds
        categorys {
            categoryId
            formattedName
            heading
            name
            priority
            slug
        }
        subCategorys {
            subCategoryId
            formattedName
            heading
            name
            parentCategoryIds
            priority
            productType
            slug
        }
        brand {
            name
            formattedName
            slug
            rating1
            rating2
            rating1Heading
            rating2Heading
            rating3Heading
            rating1Text
            rating2Text
            rating1TextExplainer
            rating2TextExplainer
            rating1BulletPointsPlus
            rating1BulletPointsMinus
            rating2BulletPointsPlus
            rating2BulletPointsMinus
            customerReviewRating
            customerReviewRatingLength
            customerReviewRatingUrl
        }
        imageKeys
        # images {
        #     childImageSharp {
        #         gatsbyImageData(
        #             width: 400
        #             placeholder: BLURRED
        #             formats: [AUTO, WEBP]
        #         )
        #     }
        # }
        featureSlugs
        rating1
        rating2
        rating3
        # ratingsTotal
        rating1Heading
        rating2Heading
        rating3Heading
        rating1Text
        rating2Text
        rating3Text
        rating1TextExplainer
        rating2TextExplainer
        rating3TextExplainer
        rating1Features
        rating2Features
        rating3Features
        featureBulletPoints
        review
        updatedAt
    }
`;

export const ProductQueryVariantFragment = graphql`
    fragment ProductVariantFields on Product {
        PK
        SK
        givenName
        formattedName
        variantName
        slug
        categoryIds
        subCategoryIds
        categorys {
            categoryId
            slug
        }
        subCategorys {
            subCategoryId
            slug
        }

        imageKeys
        # images {
        #     childImageSharp {
        #         gatsbyImageData(
        #             width: 200
        #             placeholder: BLURRED
        #             formats: [AUTO, WEBP]
        #         )
        #     }
        # }
    }
`;

export const ProductQueryPriceFragment = graphql`
    fragment ProductPriceFields on Product {
        variantPrices {
            gb @include(if: $includeRegionPricesGB) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            au @include(if: $includeRegionPricesAU) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            us @include(if: $includeRegionPricesUS) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            ca @include(if: $includeRegionPricesCA) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
            nz @include(if: $includeRegionPricesNZ) {
                supplierId
                value
                subVariantName
                region
                productUrl
                supplier {
                    supplierId
                    name
                    formattedName
                    slug
                    url
                    deliveryFee
                    deliveryFreeOver
                }
                createdAt
            }
        }
        variantPriceMetadata {
            gb @include(if: $includeRegionPricesGB) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            au @include(if: $includeRegionPricesAU) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            us @include(if: $includeRegionPricesUS) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            ca @include(if: $includeRegionPricesCA) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
            nz @include(if: $includeRegionPricesNZ) {
                subVariantName
                lowestCurrentPrice
                highestCurrentPrice
                highestHistoricPrice
                lowestHistoricPrice
                regularPrice
                updatedAt
            }
        }
        variantPriceChanges {
            gb @include(if: $includeRegionPricesGB) {
                valueChange
                value
                subVariantName
                createdAt
            }
            au @include(if: $includeRegionPricesAU) {
                valueChange
                value
                subVariantName
                createdAt
            }
            us @include(if: $includeRegionPricesUS) {
                valueChange
                value
                subVariantName
                createdAt
            }
            ca @include(if: $includeRegionPricesCA) {
                valueChange
                value
                subVariantName
                createdAt
            }
            nz @include(if: $includeRegionPricesNZ) {
                valueChange
                value
                subVariantName
                createdAt
            }
        }
    }
`;


export const query = graphql`
    query ProductTemplate(
        $PK: String!,
        $SK: String!,
        $categoryId: Int!,
        $subCategoryId: Int,
        $hasSubCategoryId: Boolean!,
        $includeProductVariant1: Boolean!,
        $includeProductVariant2: Boolean!,
        $includeProductVariant3: Boolean!,
        $includeProductVariant4: Boolean!,
        $includeProductVariant5: Boolean!,
        $includeProductVariant6: Boolean!,
        $includeProductVariant7: Boolean!,
        $includeProductVariant8: Boolean!,
        $includeProductVariant9: Boolean!,
        $includeProductVariant10: Boolean!,
        $includeProductVariant11: Boolean!,
        $includeProductVariant12: Boolean!,
        $includeRegionPricesGB: Boolean!,
        $includeRegionPricesAU: Boolean!,
        $includeRegionPricesUS: Boolean!,
        $includeRegionPricesCA: Boolean!,
        $includeRegionPricesNZ: Boolean!,
        $productVariantSK1: String,
        $productVariantSK2: String,
        $productVariantSK3: String,
        $productVariantSK4: String,
        $productVariantSK5: String,
        $productVariantSK6: String,
        $productVariantSK7: String,
        $productVariantSK8: String,
        $productVariantSK9: String,
        $productVariantSK10: String,
        $productVariantSK11: String,
        $productVariantSK12: String,
    ) {
        product: product(PK: {eq: $PK}, SK: {eq: $SK}) {
            ...ProductFields
            ...ProductPriceFields
        }
        category: category(categoryId: {eq: $categoryId}) {
            categoryId
            formattedName
            heading
            name
            priority
            slug
        }
        subCategory: subCategory(subCategoryId: {eq: $subCategoryId}) @include(if: $hasSubCategoryId) {
            subCategoryId
            formattedName
            heading
            name
            priority
            productType
            slug
        }
        productVariant1: product(PK: {eq: $PK}, SK: {eq: $productVariantSK1}) @include(if: $includeProductVariant1) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant2: product(PK: {eq: $PK}, SK: {eq: $productVariantSK2}) @include(if: $includeProductVariant2) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant3: product(PK: {eq: $PK}, SK: {eq: $productVariantSK3}) @include(if: $includeProductVariant3) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant4: product(PK: {eq: $PK}, SK: {eq: $productVariantSK4}) @include(if: $includeProductVariant4) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant5: product(PK: {eq: $PK}, SK: {eq: $productVariantSK5}) @include(if: $includeProductVariant5) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant6: product(PK: {eq: $PK}, SK: {eq: $productVariantSK6}) @include(if: $includeProductVariant6) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant7: product(PK: {eq: $PK}, SK: {eq: $productVariantSK7}) @include(if: $includeProductVariant7) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant8: product(PK: {eq: $PK}, SK: {eq: $productVariantSK8}) @include(if: $includeProductVariant8) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant9: product(PK: {eq: $PK}, SK: {eq: $productVariantSK9}) @include(if: $includeProductVariant9) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant10: product(PK: {eq: $PK}, SK: {eq: $productVariantSK10}) @include(if: $includeProductVariant10) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant11: product(PK: {eq: $PK}, SK: {eq: $productVariantSK11}) @include(if: $includeProductVariant11) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
        productVariant12: product(PK: {eq: $PK}, SK: {eq: $productVariantSK12}) @include(if: $includeProductVariant12) {
            ...ProductVariantFields
            ...ProductPriceFields
        }
    }
`;

export default ProductTemplate;






